<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="isProfilePage ? getProfilePaymentsWithDictionaries : getCompanyPaymentsWithDictionaries"
      :loading="loading"
      :options.sync="options"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :server-items-length="
        isProfilePage ? getProfilePaymentsTotalCount : getCompanyPaymentsTotalCount || options.itemsPerPage * totalPages
      "
      :footer-props="footerProps"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar :color="isPaymentsPage ? 'indigo lighten-5' : ''" flat>
          <template v-if="isPaymentsPage">
            <BackButton />
            <v-toolbar-title>Платежі</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
          </template>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="searchText"
            class="shrink mr-10"
            append-icon="mdi-magnify"
            label="Пошук"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>

      <template v-slot:header>
        <thead>
          <tr>
            <th style="border-right: thin solid rgba(0, 0, 0, 0.12)"></th>
            <th
              colspan="5"
              class="text-center"
              :style="{
                borderRight: 'thin solid rgba(0, 0, 0, 0.12)',
                borderTop: !isPaymentsPage ? 'thin solid rgba(0, 0, 0, 0.12)' : 'none',
              }"
            >
              Реквізити платежа
            </th>
            <th
              colspan="5"
              class="text-center"
              :style="{ borderTop: !isPaymentsPage ? 'thin solid rgba(0, 0, 0, 0.12)' : 'none' }"
            >
              Місце
            </th>
          </tr>
        </thead>
      </template>

      <template v-slot:header.type="{ header }">
        <TableTitleFilter
          :values="selectedTypes"
          :options="paymentTypesDictionary"
          :title-text="header.text"
          :disabled="!isPaymentsPage && !isPromotionCardPage && !isPromotionListPage"
          item-text="name"
          @select="(selected) => (selectedTypes = selected)"
          @ok="applyFilter('type', selectedTypes)"
        />
      </template>
      <template v-slot:header.payment_status="{ header }">
        <TableTitleFilter
          :values="selectedStatuses"
          :options="paymentStatusesDictionary"
          :title-text="header.text"
          item-text="name"
          @select="(selected) => (selectedStatuses = selected)"
          @ok="applyFilter('payment_status', selectedStatuses)"
        />
      </template>
      <!--      <template v-slot:header.initiator="{ header }">-->
      <!--        <TableTitleFilter-->
      <!--          :values="selectedInitiators"-->
      <!--          :options="initiators"-->
      <!--          :options-meta="initiatorsMeta"-->
      <!--          :options-params="initiatorsOptionsData"-->
      <!--          :title-text="header.text"-->
      <!--          item-value="id"-->
      <!--          @select="(selected) => (selectedInitiators = selected)"-->
      <!--          @ok="applyFilter('user', selectedInitiators)"-->
      <!--        >-->
      <!--          <template v-slot:item="{ item }">{{ item.first_name }} {{ item.last_name }}</template>-->
      <!--          <template v-slot:selection="{ item }">{{ item.first_name }} {{ item.last_name }}</template>-->
      <!--        </TableTitleFilter>-->
      <!--      </template>-->
      <template v-slot:header.created_at="{ header }">
        <TableTitleDateFilter
          :title-text="header.text"
          @select="(selected) => (selectedDates = selected)"
          @ok="applyDateFilter('created_at', selectedDates)"
        />
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ item.updated_at ? item.updated_at : item.created_at | getShortTime }}
        <sup class="">{{ item.updated_at ? item.updated_at : item.created_at | getShortDate }}</sup>
      </template>
      <template v-slot:item.id="{ item }">
        <router-link
          :to="{ name: 'PaymentCard', params: { id: currentCompanyId, paymentId: item.id } }"
          class="text-decoration-none"
        >
          {{ item.id }}
        </router-link>
      </template>
      <template v-slot:item.type="{ item }">
        {{ item.payment_type_name }}
      </template>

      <!--  *** UC69 https://docs.google.com/document/d/1xgmU3i1skAEaDercf_SV0RRPuqInzSrGIzNzWK8XvQo/edit?tab=t.0   -->
      <template v-slot:item.initiator="{ item }">
        <template v-if="isPromotionOrUserInitiatorType(item)">
          <router-link
            v-if="item.promotion"
            :to="{ name: 'PromotionCard', params: { id: currentCompanyId, promotionId: item.promotion.id } }"
            class="text-decoration-none"
          >
            <span class="text-caption">Акція #</span>{{ item.promotion.id }}<span>:</span> {{ item.promotion.title }}
          </router-link>
          <router-link
            v-else-if="item.created_by"
            :to="{ name: 'UserCard', params: { id: currentCompanyId, userId: item.created_by.id } }"
            class="text-decoration-none"
          >
            {{ item.created_by.first_name }} {{ item.created_by.last_name }}
          </router-link>
        </template>
        <template v-if="isClientInitiatorType(item)">
          <router-link
            v-if="item.user"
            :to="{ name: 'ClientCard', params: { id: currentCompanyId, clientId: item.user.id } }"
            class="text-decoration-none"
          >
            {{ item.user.first_name }} {{ item.user.last_name }}
          </router-link>
        </template>
        <template v-if="isUserInitiatorType(item)">
          <router-link
            v-if="item.created_by"
            :to="{ name: 'UserCard', params: { id: currentCompanyId, userId: item.created_by.id } }"
            class="text-decoration-none"
          >
            {{ item.created_by.first_name }} {{ item.created_by.last_name }}
          </router-link>
        </template>
      </template>
      <template v-slot:item.company="{ item }">
        <router-link
          v-if="item.company"
          :to="{ name: 'CompanyCard', params: { id: currentCompanyId } }"
          :class="[{ deleted: item.company.status === 3 }, 'text-decoration-none']"
        >
          {{ item.company.name }}
        </router-link>
      </template>
      <template v-slot:item.service_point="{ item }">
        <router-link
          v-if="item.service_point"
          :to="{ name: 'ServicePointCard', params: { id: currentCompanyId, servicePointId: item.service_point.id } }"
          :class="[{ deleted: item.service_point.status === 3 }, 'text-decoration-none']"
        >
          {{ item.service_point.name }},
          <span v-if="item.service_point.address">
            {{ item.service_point.address.city }}, {{ item.service_point.address.street }},
            {{ item.service_point.address.building_number }}
          </span>
        </router-link>
      </template>
      <template v-slot:item.terminal="{ item }">
        <router-link
          v-if="item.terminal"
          :to="{ name: 'TerminalCard', params: { id: currentCompanyId, terminalId: item.terminal.id } }"
          :class="[{ deleted: item.terminal.status === 3 }, 'text-decoration-none']"
        >
          T{{ item.terminal.id }}
        </router-link>
      </template>
      <template v-slot:item.receiver="{ item }">
        <template v-if="isClientReceiverType(item)">
          <router-link
            v-if="item.user"
            :to="{ name: 'ClientCard', params: { id: currentCompanyId, clientId: item.user.id } }"
            class="text-decoration-none"
          >
            {{ item.user.first_name }} {{ item.user.last_name }}
          </router-link>
        </template>
        <template v-else>
          <router-link
            v-if="item.device"
            :to="{ name: 'DeviceCard', params: { id: currentCompanyId, deviceId: item.device.id } }"
            :class="[{ deleted: item.device.status === 3 }, 'text-decoration-none']"
          >
            Автомат {{ item.device.name }}{{ item.device.id }}
          </router-link>
          <router-link
            v-else-if="item.terminal"
            :to="{ name: 'TerminalCard', params: { id: currentCompanyId, terminalId: item.terminal.id } }"
            :class="[{ deleted: item.terminal.status === 3 }, 'text-decoration-none']"
          >
            Термінал T{{ item.terminal.id }}
          </router-link>
          <router-link
            v-else-if="item.service_point"
            :to="{ name: 'ServicePointCard', params: { id: currentCompanyId, servicePointId: item.service_point.id } }"
            :class="[{ deleted: item.service_point.status === 3 }, 'text-decoration-none']"
          >
            Точка {{ item.service_point.id }}, {{ item.service_point.name }}
          </router-link>
          <router-link
            v-else-if="item.company"
            :to="{ name: 'ServicePointCard', params: { id: currentCompanyId, servicePointId: item.company.id } }"
            :class="[{ deleted: item.company.status === 3 }, 'text-decoration-none']"
          >
            Компанія {{ item.company.id }}, {{ item.company.name }}
          </router-link>
          <span v-else>N/A</span>
        </template>
      </template>
      <template v-slot:item.payment_status="{ item }">
        {{ item.payment_status_name }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip v-if="item.user && canForwardToBonus(item.type, item.payment_status)" bottom :open-on-focus="false">
          <template v-slot:activator="{ on, attrs }">
            <button class="v-icon v-icon--link mdi mr-1" @click="forwardToBonus(item)">
              <img src="@/assets/images/cashback.svg" alt="Cashback icon" v-bind="attrs" v-on="on" width="16" height="16" />
            </button>
          </template>
          <span>Повернути на бонусний рахунок</span>
        </v-tooltip>
        <v-tooltip bottom :open-on-focus="false">
          <template v-slot:activator="{ on, attrs }">
            <v-icon small @click="navigateToPaymentCard(item)" v-bind="attrs" v-on="on"> mdi-eye-outline </v-icon>
          </template>
          <span>Переглянути</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="resetData"> Оновити </v-btn>
      </template>
    </v-data-table>
    <ConfirmActionModal @closeModal="showModal = false" :showModal="showModal">
      <template v-slot:title>Підтвердження</template>
      <template v-slot:text>Повернути цей платіж на бонусний рахунок?</template>
      <template v-slot:buttons>
        <v-btn @click="forwardToBonusConfirm" color="primary" text>Так</v-btn>
        <v-btn @click="showModal = false" text>Нi</v-btn>
      </template>
    </ConfirmActionModal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { ROUTER_LINKS } from '@/const/router-links.enum'
import ConvertDate from '@/mixins/convertDate'
import resetTableData from '@/mixins/resetTableData'
import ConvertDevicesTypes from '@/mixins/convertDevicesTypes'
import BackButton from '@/components/common/BackButton'
import ConfirmActionModal from '@/components/dialogs/ConfirmActionModal'
import sortUtils from '@/mixins/sortUtils'
import TableTitleFilter from '@/components/common/filters/TableTitleFilter/TableTitleFilter'
import filterUtils from '@/mixins/filterUtils'
import searchUtils from '@/mixins/searchUtils'
import TableTitleDateFilter from '@/components/common/filters/TableTitleFilter/TableTitleDateFilter.vue'

const PRESET_PAYMENTS_TYPES = [5, 9, 10, 11, 12, 13]
const PRESET_PROMOTIONS_LIST_TYPES = [10, 11, 12, 13]
export default {
  name: 'Payments',

  components: { TableTitleDateFilter, TableTitleFilter, ConfirmActionModal, BackButton },

  mixins: [ConvertDevicesTypes, ConvertDate, resetTableData, sortUtils, filterUtils, searchUtils],

  data: () => ({
    ROUTER_LINKS: ROUTER_LINKS,
    loading: false,
    headers: [
      { text: 'Створено/оновлено', value: 'created_at', sortable: true },
      { text: 'ID', align: 'start', sortable: true, value: 'id' },
      { text: 'Тип', value: 'type', sortable: true },
      { text: 'Сума', value: 'amount', sortable: true },
      { text: 'Статус', value: 'payment_status', sortable: true },
      { text: 'Ініціатор', value: 'initiator', sortable: false },
      { text: 'Компанія', value: 'company', sortable: false },
      { text: 'Торгова точка', value: 'service_point', sortable: true },
      { text: 'Т', value: 'terminal', sortable: true },
      { text: 'Отримувач', value: 'receiver', sortable: false },
      // { text: 'Змінено', value: 'updated_at', sortable: false },
      { text: '', value: 'actions', sortable: false, width: '125px', align: 'right' },
    ],
    sortBy: 'id',
    sortDesc: true,
    options: {},
    showModal: false,
    clickedPaymentId: null,
    totalPages: 2,
    payload: null,
    selectedTypes: [],
    selectedStatuses: [],
    searchFields: [
      'id',
      'user.first_name',
      'user.last_name',
      'created_by.first_name',
      'created_by.last_name',
      'promotion.title',
      'device.id',
      'device.name',
      'terminal.id',
      'terminal.name',
      'service_point.id',
      'service_point.name',
    ],
  }),

  computed: {
    ...mapState('dictionaries', ['paymentTypesDictionary', 'paymentStatusesDictionary', 'machinesTypesDictionary']),
    ...mapState('companies', ['currentCompany']),
    ...mapGetters('payments', ['getCompanyPaymentsTotalCount', 'getCompanyPaymentsWithDictionaries']),
    ...mapGetters('profile', ['getProfilePaymentsTotalCount', 'getProfilePaymentsWithDictionaries']),
    ...mapState('profile', ['user']),

    currentCompanyId() {
      return this.$route.params.id || this.currentCompany?.id || ''
    },
    promotionId() {
      return this.$route.params.promotionId || null
    },
    isPaymentsPage() {
      return this.$route.name === 'PaymentsList'
    },
    isPromotionListPage() {
      return this.$route.name === 'Promotions'
    },
    isPromotionCardPage() {
      return this.$route.name === 'PromotionCard'
    },
    isProfilePage() {
      return this.$route.name === 'Profile'
    },
    initiatorOrReceiver() {
      if (!this.isPaymentsPage) {
        return this.$route.params.clientId || this.$route.params.userId || this.user.id
      } else return null
    },
    footerProps() {
      return {
        'items-per-page-options': [5, 10, 15, 20],
        'show-current-page': true,
        'show-first-last-page': this.isProfilePage || this.getCompanyPaymentsTotalCount,
        ...(!this.isProfilePage && !this.getCompanyPaymentsTotalCount && { 'page-text': '{0}-{1}' }),
      }
    },
  },

  watch: {
    options: {
      handler(val) {
        if (!this.getCompanyPaymentsTotalCount && val.page === this.totalPages) {
          this.totalPages++
        }
        this.paginateTo()
      },
      deep: true,
    },
  },

  created() {
    this.initialize()
  },

  beforeDestroy() {
    this.clearData()
  },

  methods: {
    ...mapActions('payments', ['loadCompanyPayments', 'refundPayment']),
    ...mapActions('profile', ['loadProfilePayments']),
    ...mapActions('logs', ['displayWarningAlert']),
    ...mapMutations('payments', ['SET_PAYMENTS']),
    ...mapMutations('profile', ['SET_PROFILE_PAYMENTS']),

    initialize() {
      if (!this.isProfilePage) {
        this.payload = {
          companyId: this.currentCompanyId,
        }
      }
      if (!this.isPaymentsPage && !this.isPromotionListPage && !this.isPromotionCardPage) {
        this.filterObject = {
          type: PRESET_PAYMENTS_TYPES,
          filter: {
            user: this.initiatorOrReceiver,
            createdBy: this.initiatorOrReceiver,
          },
        }
        const presetPaymentTypesDictionary = this.paymentTypesDictionary.filter((item) =>
          PRESET_PAYMENTS_TYPES.includes(item.value)
        )
        this.selectedTypes = [...this.selectedTypes, ...presetPaymentTypesDictionary]
      }
      if (this.isPromotionCardPage) {
        this.filterObject = {
          promotion: this.promotionId,
        }
      }
      if (this.isPromotionListPage) {
        this.filterObject = {
          type: PRESET_PROMOTIONS_LIST_TYPES,
          // *** search all created_by: null ***
          createdBy: null,
        }
      }
    },
    async paginateTo() {
      if (!this.currentCompanyId && !this.isProfilePage) {
        await this.displayWarningAlert({ message: 'Оберіть компанію' })
        await this.$router.replace('/spa')
        return
      }
      this.loading = true
      this.payload = {
        ...this.payload,
        page: this.options.page,
        limit: this.options.itemsPerPage,
        sort: this.sortObject,
        search: this.searchObject,
        ...this.filterObject,
      }
      try {
        if (this.isProfilePage) {
          await this.loadProfilePayments(this.payload)
        } else {
          await this.loadCompanyPayments(this.payload)
        }
      } finally {
        this.loading = false
      }
    },
    forwardToBonus(item) {
      this.showModal = true
      this.clickedPaymentId = item.id
    },
    async forwardToBonusConfirm() {
      this.showModal = false
      const params = {
        companyId: this.currentCompanyId,
        paymentId: this.clickedPaymentId,
      }
      await this.refundPayment(params)
      await this.paginateTo()
    },
    navigateToPaymentCard(item) {
      const clickedItemId = item.id
      this.$router.push({ name: 'PaymentCard', params: { id: this.currentCompanyId.toString(), paymentId: clickedItemId } })
    },
    convertManufactureDate() {
      // const date = new Date(this.editedItem.manufacture_date)
      // this.convertedManufactureDate = date.toISOString().split('T')[0]
      this.convertedManufactureDate = this.editedItem.manufacture_date.split('T')[0]
    },
    canForwardToBonus(type, status) {
      return (type === 2 || type === 5) && status === 3
    },
    isPromotionOrUserInitiatorType(item) {
      return [10, 11].includes(item.type)
    },
    isClientInitiatorType(item) {
      return [2, 5, 9].includes(item.type)
    },
    isUserInitiatorType(item) {
      return [4, 7, 8, 12, 13].includes(item.type)
    },
    isClientReceiverType(item) {
      return [9, 10, 11, 12, 13].includes(item.type)
    },
    clearData() {
      this.SET_PAYMENTS([])
      this.SET_PROFILE_PAYMENTS([])
    },
  },
}
</script>

<style scoped lang="scss">
@import 'src/assets/scss/vars';
.deleted {
  color: $red;
  pointer-events: none;
}
</style>
